import { getExternalSiteUrl, getExternalSportsbookUrl } from '../../../config'

import { replaceMirrorUrlHelper } from '../../../config/originSingleton'
import { formatMessage } from '../../../i18n'
// TODO: Get value from useEnvData hook

const EXTERNAL_BASE_URI = getExternalSiteUrl()
const EXTERNAL_SPORTSBOOK_URI = getExternalSportsbookUrl()

export const getExternalUri = () => {
  return replaceMirrorUrlHelper(EXTERNAL_BASE_URI)
}

const URL = getExternalUri()

export const headerLinks = (siteKey, key) => {
  const links = {
    //TODO: get this URL dinamically from backend.
    bolnasite: {
      blackjack: '/?gameId=3073&extGameId=2001&ProviderID=13',
      banking: `${URL}/cashier/deposit`,
    },
    sbnasite: {
      blackjack: '/?gameId=2556&extGameId=2001&ProviderID=13',
      banking: `${URL}/cashier/deposit`,
    },
    lvnasite: {
      blackjack: '/?gameId=2843&extGameId=2001&ProviderID=13',
      banking: `${EXTERNAL_BASE_URI}/cashier`,
    },
  }
  return links[siteKey][key]
}

export const buildLinks = siteKey => {
  const URL = getExternalUri()
  // @TODO: remove after LV SAS ROLLOUT
  const liveBettingUrl = '/sportsbook/live'
  const multiBrandLinks = {
    bolnasite: [
      [
        {
          name: formatMessage('noun:sports'),
          to: `${URL}/sportsbook`,
          icon: 'sports',
        },
        {
          name: formatMessage('noun:live-betting'),
          to: `${URL}/sportsbook/live-betting`,
          icon: 'live',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
        },
        {
          name: formatMessage('noun:race-book'),
          to: `${URL}/horse-betting`,
          icon: 'racebook',
        },
        {
          name: formatMessage('noun:poker'),
          to: `${URL}/poker`,
          icon: 'poker',
        },
        {
          name: formatMessage('noun:e-sports'),
          to: `${URL}/esports`,
          icon: 'esports',
        },
        {
          name: formatMessage('noun:contests'),
          to: `${URL}/contests`,
          icon: 'contest',
        },
        {
          name: formatMessage('noun:vip-rewards'),
          to: `${URL}/vip-rewards`,
          icon: 'loyalty',
        },
        {
          name: formatMessage('noun:promos'),
          to: `${URL}/promotions`,
          icon: 'promo',
        },
      ],
      [
        {
          name: formatMessage('noun:sports'),
          to: `${URL}/sportsbook`,
          icon: 'sports',
        },
        {
          name: formatMessage('noun:live-betting'),
          to: `${URL}/sportsbook/live-betting`,
          icon: 'live',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
        },
        {
          name: formatMessage('noun:race-book'),
          to: `${URL}/horse-betting`,
          icon: 'racebook',
        },
        {
          name: formatMessage('noun:poker'),
          to: `${URL}/poker`,
          icon: 'poker',
        },
        {
          name: formatMessage('noun:e-sports'),
          to: `${URL}/esports`,
          icon: 'esports',
        },
        {
          name: formatMessage('noun:contests'),
          to: `${URL}/contests`,
          icon: 'contest',
        },
        {
          name: formatMessage('noun:promos'),
          to: `${URL}/promotions`,
          icon: 'promo',
        },
        {
          name: formatMessage('noun:vip-rewards'),
          to: `${URL}/vip-rewards`,
          icon: 'loyalty',
          active: true,
        },
      ],
      [
        {
          name: formatMessage('noun:cashier'),
          to: `${URL}/cashier/deposit`,
          icon: 'cashier',
        },
        {
          name: formatMessage('noun:sports'),
          to: `${URL}/sportsbook`,
          icon: 'sports',
          overIcon: 'sports',
        },
        {
          name: formatMessage('noun:live'),
          to: `${URL}/sportsbook/live-betting`,
          icon: 'live',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
        },
      ],
    ],
    sbnasite: [
      [
        {
          name: formatMessage('noun:sports'),
          to: `${URL}/sportsbook`,
          icon: 'sports',
          overIcon: 'sports-blue',
        },
        {
          name: formatMessage('noun:live-betting'),
          to: `${URL}/sportsbook/live-betting`,
          icon: 'live-betting',
          overIcon: 'live-betting-blue',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
          overIcon: 'casino-blue',
        },
        {
          name: formatMessage('noun:race-book'),
          to: `${URL}/racebook`,
          icon: 'horses',
          overIcon: 'horses-blue',
        },
        {
          name: formatMessage('noun:poker'),
          to: `${URL}/poker`,
          icon: 'poker',
          overIcon: 'poker-blue',
        },
        {
          name: formatMessage('noun:e-sports'),
          to: `${URL}/esports`,
          icon: 'esports',
          overIcon: 'esports-blue',
        },
        {
          name: formatMessage('noun:contests'),
          to: `${URL}/contests`,
          icon: 'contests',
          overIcon: 'contests-blue',
        },
        {
          name: formatMessage('noun:promos'),
          to: `${URL}/promotions`,
          icon: 'promos',
          overIcon: 'promos-blue',
        },
      ],
      [
        {
          name: formatMessage('noun:sports'),
          to: `${URL}/sportsbook`,
          icon: 'sports',
          overIcon: 'sports',
        },
        {
          name: formatMessage('noun:live-betting'),
          to: `${URL}/sportsbook/live-betting`,
          icon: 'live-betting',
          overIcon: 'live-betting',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
          overIcon: 'casino',
        },
        {
          name: formatMessage('noun:race-book'),
          to: `${URL}/horse-betting`,
          icon: 'horses',
          overIcon: 'horses-blue',
        },
        {
          name: formatMessage('noun:poker'),
          to: `${URL}/poker`,
          icon: 'poker',
          overIcon: 'poker',
        },
        {
          name: formatMessage('noun:e-sports'),
          to: `${URL}/esports`,
          icon: 'esports',
          overIcon: 'esports',
        },
        {
          name: formatMessage('noun:contests'),
          to: `${URL}/contests`,
          icon: 'contests',
          overIcon: 'contests-blue',
        },
        {
          name: formatMessage('noun:promos'),
          to: `${URL}/promotions`,
          icon: 'promos',
          overIcon: 'promos-blue',
        },
        {
          name: formatMessage('noun:login'),
          to: `login`,
          icon: 'login',
          overIcon: 'login',
        },
      ],
      [
        {
          name: formatMessage('noun:cashier'),
          to: `${URL}/cashier`,
          icon: 'cashier-mobile',
          overIcon: 'cashier-mobile',
        },
        {
          name: formatMessage('noun:sports'),
          to: `${URL}/sportsbook`,
          icon: 'sports',
          overIcon: 'sports',
        },
        {
          name: formatMessage('noun:live'),
          to: `${URL}/sportsbook/live-betting`,
          icon: 'live-betting',
          overIcon: 'live-betting',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
          overIcon: 'casino-blue',
        },
      ],
    ],
    lvnasite: [
      [
        {
          name: formatMessage('noun:sports'),
          to: `${EXTERNAL_SPORTSBOOK_URI}`,
          icon: 'sports',
          overIcon: 'sports-blue',
        },
        {
          name: formatMessage('noun:live-betting'),
          to: `${EXTERNAL_SPORTSBOOK_URI}${liveBettingUrl}`,
          icon: 'live-betting',
          overIcon: 'live-betting-blue',
        },
        {
          name: formatMessage('noun:race-book'),
          to: `${EXTERNAL_BASE_URI}/horse-betting`,
          icon: 'horses',
          overIcon: 'horses-blue',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
          overIcon: 'casino-blue',
        },
        {
          name: formatMessage('noun:live-casino'),
          to: `/games/live-casino`,
          icon: 'live-casino',
          overIcon: 'casino-blue',
        },
        {
          name: formatMessage('noun:cashier'),
          to: `${EXTERNAL_BASE_URI}/banking`,
          icon: 'cashier-mobile',
          overIcon: 'cashier-mobile',
        },
      ],
      [
        {
          name: formatMessage('noun:sports'),
          to: `${EXTERNAL_SPORTSBOOK_URI}`,
          icon: 'sports',
          overIcon: 'sports',
        },
        {
          name: formatMessage('noun:live-betting'),
          to: `${EXTERNAL_SPORTSBOOK_URI}${liveBettingUrl}`,
          icon: 'live-betting',
          overIcon: 'live-betting',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
          overIcon: 'casino',
        },
        {
          name: formatMessage('noun:race-book'),
          to: `${EXTERNAL_BASE_URI}/horse-betting`,
          icon: 'horses',
          overIcon: 'horses-blue',
        },
        {
          name: formatMessage('noun:login'),
          to: `login`,
          icon: 'login',
          overIcon: 'login',
        },
      ],
      [
        {
          name: formatMessage('noun:cashier'),
          to: `${EXTERNAL_BASE_URI}/banking`,
          icon: 'cashier-mobile',
          overIcon: 'cashier-mobile',
        },
        {
          name: formatMessage('noun:sports'),
          to: `${EXTERNAL_SPORTSBOOK_URI}`,
          icon: 'sports',
          overIcon: 'sports',
        },
        {
          name: formatMessage('noun:live'),
          to: `${EXTERNAL_SPORTSBOOK_URI}${liveBettingUrl}`,
          icon: 'live-betting',
          overIcon: 'live-betting',
        },
        {
          name: formatMessage('noun:casino'),
          to: '/',
          icon: 'casino',
          overIcon: 'casino-blue',
        },
      ],
    ],
  }

  return multiBrandLinks[siteKey]
}

export const getGTMDataObject = (name, index, total, text, redirectUrl) => {
  const data = {
    'event': 'element_interaction',
    'interaction_type': 'click',
    'element_type': 'header',
    'element_subtype': name,
    'element_layout': 'button',
    'element_name': name,
    'element_content': 'casino',
    'element_id': '12345',
    'element_position': {
      'index': index,
     'total': total
      },
    'element_text': text,
    'element_redirect_url': redirectUrl,
    }
    window.dataLayer = window.dataLayer  || [];
        window.dataLayer.push(data);
}
