import 'need-help-popup'
import { useTheme } from '@material-ui/core'
import { CustomTheme } from '../../CustomTheme/types'
import { useEffect, useRef } from 'react'
import { getBrandFromGsetting } from 'src/business'


const NeedHelpPopUp = () => {
    const theme  = useTheme<CustomTheme>()
    const brand = getBrandFromGsetting()
    const needHelpRef = useRef(null)

    useEffect(() => {
        if (needHelpRef) {
            const { current } = needHelpRef
            current.intercomOnly = true
        }
    }, [needHelpRef])

    return (
    <div>
        {/* @ts-expect-error need-help-popup is an external web component without typescript definition */}
        <need-help-popup ref={needHelpRef} color={theme.custom.needHelp?.mainColor || theme.custom.palette.quaternary800} brand={brand} title='Help Center'></need-help-popup>
    </div>
    )
}
export default NeedHelpPopUp
