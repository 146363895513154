import Env from '@env';
import { useCallback, useContext, createContext } from 'react'
import keycloak from './keycloak'
import { useCashierInformation } from '../asyncData'
import { cashierOauth } from '../config'
import { replaceMirrorUrlHelper } from '../config/originSingleton';
import { intercomShutdown } from '../context/Intercom'

export const AuthContext = createContext({
  initialized: false,
  keycloak
});

export const useAuthContext = () => {
  return useContext(AuthContext);
}

const getCashierOauth = () => {
  return replaceMirrorUrlHelper(Env.get('VITE_CASHIER_OAUTH_LOGOUT_URL'))
}

const AUTH_3_FLAG = Env.get('VITE_AUTH_3_FLAG')
const cashierLogoutUrl = getCashierOauth() || ''

const useAuth = () => {
  const { keycloak, initialized } = useContext(AuthContext)

  // NOTE create urls and redirect to enable back button in register/login pages
  const redirectToJoin = useCallback(
    options => {
      window.localStorage.setItem('AUTH_ACTION', 'JOIN');
      window.location.assign(keycloak.createRegisterUrl(options))
    },
    [keycloak],
  )
  const redirectToLogin = useCallback(
    options => {
      window.localStorage.setItem('AUTH_ACTION', 'LOGIN');
      window.location.assign(keycloak.createLoginUrl(options))
    },
    [keycloak],
  )
  const { data, isSuccess } = useCashierInformation({
    enabled: Boolean(keycloak.authenticated) && cashierOauth(),
    useErrorBoundary: true,
  })

  const logout = useCallback(() => {
    // maybe using env we can remove completely API call
    const logoutUrl = getUrlForLogout()
    keycloak.logout({ redirectUri: logoutUrl })
    intercomShutdown()
  },
    [keycloak, isSuccess],
  )

  const getUrlForLogout = () => {
    if (cashierLogoutUrl || (cashierOauth() && data.Url)) {
      const redirectUrl = window.location.origin
      const index = data?.Url?.indexOf('LoginWrapper')
      const proxyBaseUrl = cashierLogoutUrl || (index !== -1 ? data.Url.substring(0, index) : data.Url)
      const logoutUrl = `${proxyBaseUrl}oauth2/sign_out?rd=${redirectUrl}`
      return logoutUrl
    } else {
      return window.location.origin
    }
  }

  const getCustomerId = () => {
    if (AUTH_3_FLAG) {
      return keycloak.tokenParsed?.preferred_username
    } else {
      return keycloak.idTokenParsed?.['na-api-response']?.CustomerID || keycloak.tokenParsed?.preferred_username
    }
  }
  return {
    initialized,
    logged: Boolean(keycloak.authenticated),
    sessionId: keycloak.sessionId,
    username: keycloak.tokenParsed?.preferred_username,
    email: keycloak.tokenParsed?.email,
    firstName: keycloak.tokenParsed?.given_name,
    lastName: keycloak.tokenParsed?.family_name,
    fullName: keycloak.tokenParsed?.name,
    customerId: getCustomerId(),
    customerSub: keycloak?.idTokenParsed?.sub,
    login: keycloak.login,
    redirectToJoin,
    redirectToLogin,
    logout,
  }
}

export default useAuth
