import merge from 'deepmerge'
import baseTheme from '../baseTheme'
import brandPalette from './palette'
import UniversalFooter from '../../../ui/external-components/Footer'
import noResultsSvg from '../../../assets/icons/no-results.svg?url'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const superSlotsPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette();
  const palette = merge(brandPalette, cssVariablesPalette);

  return {
    palette,
    brand: {
      namespace: 'ssnasite',
      name: 'Super Slots',
      website: 'SuperSlots.ag',
      email: 'help@superslotscs.ag',
      help: 'https://help.superslots.ag/',
      themeColor: palette.quaternary800,
      bonusInfoUrl: 'https://help.superslots.ag/bonus-tutorial-and-faq/',
    },
    buttons: {
      primaryFrontColor: palette.quaternary800,
      primarySolidColor: baseTheme.palette.neutral1,
      secondarySolidColor: baseTheme.palette.neutral1,
      secondarySolidBg: palette.primary800,
      iconColorDisabled: baseTheme.palette.neutral1_16,
      ghostSolidBg: 'transparent',
      ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: baseTheme.palette.neutral1,
      hover: {
        ghostSolidBg: baseTheme.palette.neutral1,
        ghostSolidColor: baseTheme.palette.neutral1,
      },
    },
    gradients: {
      secondaryGradientDark: `linear-gradient(to bottom, ${palette.secondary900}, ${palette.secondary800})`,
    },
    sideMenu: {
      selectedImg: 'brightness(0) saturate(100%) invert(54%) sepia(7%) saturate(4203%) hue-rotate(179deg) brightness(106%) contrast(100%)',
      activeImg: 'brightness(0) saturate(100%) invert(3%) sepia(15%) saturate(991%) hue-rotate(53deg) brightness(98%) contrast(90%)',
    },
    tags: {
      tagBgNew: '#028247',
      tagBgExclusive: palette.placeholder,
    },
    opacity: {
      opacity40: 0.4,
      opacity90: 0.9,
    },
    fontFamily: {
      primaryFont: 'Open Sans',
      secondaryFont: 'Lato',
    },
    pageHeading: {
      bgColor: palette.quaternary700,
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: baseTheme.palette.neutral1_60,
      variant: 'filled',
    },
    bonusTable: {
      headerSize: null,
      headColor: baseTheme.palette.neutral1,
      headBgColor: null,
      tableVariant: 'dark',
    },
    selectInput: {
      border: `2px solid ${baseTheme.palette.neutral1_16}`,
      borderColorHover: baseTheme.palette.neutral1_60,
      iconColor: baseTheme.palette.neutral1,
      color: baseTheme.palette.neutral1_87,
    },
    appBar: {
      background: palette.quaternary800,
      logoHeight: '4rem',
      logoHeightMd: '3rem',
    },
    infoModal: {
      background: palette.quaternary800,
    },
    carouselsTitle: {
      iconColor: baseTheme.palette.neutral1,
      linkColor: palette.tertiary500,
    },
    needHelp: {
      mainColor: palette.placeholder,
    },
    footer: <UniversalFooter />,
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'internal',
      url: `/cashier`,
    },
    myAccount: {
      securityCentral: {
        active: true,
        style: {
          background: '#5a0d64',
          backgroundActive: 'rgba(0, 0, 0, 0.12)',
          tabItemBackgroundColor: '#3A0B4A',
          tabItemColor: 'rgba(255, 255, 255, 0.38)',
          tabItemActiveColor: '#FFC500',
          accordionSectionColor: 'rgba(255, 255, 255, 0.7)',
          accordionSectionActiveColor: '#FFC500',
          changePasswordAvatarColor: '#FFC500',
          changePasswordButtonBackgroundColor: 'linear-gradient(180deg, #FFDF00 0%, #F80 100%)',
          changePasswordTextButtonColor: '#3A0B4A',
        },
      },
    },
    intercom: {
      brand: 'superslots'
    },
    input: {
      color: baseTheme.palette.neutral1,
      colorAlt: baseTheme.palette.neutral2,
      bgColor: 'transparent',
      bgAltColor: baseTheme.palette.neutral1,
      borderColor: baseTheme.palette.neutral1_16,
      borderColorAlt: 'transparent',
      borderColorHover: baseTheme.palette.neutral1_60,
      placeholder: baseTheme.palette.neutral1_38,
      placeholderAlt: baseTheme.palette.neutral2_38,
    },
    searchModal: {
      headerBg: '#123333',
      active: false
    },
    switch: {
      thumbColor: baseTheme.palette.neutral1,
      thumbColorChecked: palette.secondary700,
      trackColor: baseTheme.palette.neutral3_60,
      trackColorChecked: palette.secondary700,
    }
  }
}

export default superSlotsPartialTheme
