import { useEffect, useState } from 'react'
import loadIcon from './getIconAssetsMap'
import { getNames } from './getIconAssetsMap'

interface IconProps {
  name?: string
  className?: string
  customStyles?: React.CSSProperties
  url?: string
}

const Icon = ({ name, className, url, customStyles }: IconProps) => {
  const [iconContent, setIconContent] = useState<string | null>(null)

  useEffect(() => {
    if (name) {
      loadIcon(name).then(content => {
        setIconContent(content)
      })
    }
  }, [name])

  if (url) {
    return (
      <img
        src={url}
        className={className}
        alt={`custom-icon-${name}`}
        style={customStyles}
        loading='lazy'
      />
    )
  }

  if (!iconContent) {
    return <span className={className} style={customStyles} />
  }

  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: iconContent }}
      style={customStyles}
    />
  )

}


Icon.iconNames = getNames()

export default Icon