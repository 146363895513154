import React from 'react'
import { createRoot } from 'react-dom/client'
import { RawIntlProvider } from 'react-intl'
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import { polyfill } from 'seamless-scroll-polyfill'
import 'array-flat-polyfill'

import { intl } from './i18n'
import { ReactKeycloakProvider } from './authentication'
import SnackbarProviderWrapper from './ui/components/Notification/SnackbarProviderWrapper'
import App from './App'
import { AsyncDataProvider } from './asyncData'
import theme from './ui/config'
import CustomTheme from './ui/CustomTheme'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { UserBalanceProvider } from './context/UserBalance'


polyfill()

// Generate custom classnames
const generateClassName = createGenerateClassName({
  productionPrefix: 'wc',
})
const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <RawIntlProvider value={intl} textComponent={React.Fragment}>
    <StylesProvider generateClassName={generateClassName}>
      <CustomTheme theme={theme}>
        <ReactKeycloakProvider>
          <AsyncDataProvider>
            <SnackbarProviderWrapper>
              <HelmetProvider>
                <BrowserRouter>
                  <UserBalanceProvider>
                    <Routes>
                      <Route path="*" element={<App />} />
                    </Routes>
                  </UserBalanceProvider>
                </BrowserRouter>
              </HelmetProvider>
            </SnackbarProviderWrapper>
          </AsyncDataProvider>
        </ReactKeycloakProvider>
      </CustomTheme>
    </StylesProvider>
  </RawIntlProvider>,
)
