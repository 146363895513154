import Cookies from 'js-cookie'
import { useTheme } from '@material-ui/core'

const useFeatureFlags = () => {
  const theme: {
    custom: {
      hasNewNavigation: boolean
    }
  } = useTheme()
  const newNavCookieEnabled = Cookies.get('websites-ff-new-nav-enabled') === 'true'
  const newNavFFEnabled = theme.custom?.hasNewNavigation
  const isNewNavigationActive = newNavCookieEnabled || newNavFFEnabled
  return { isNewNavigationActive }
}

export default useFeatureFlags
