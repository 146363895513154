import { TransitionEvent } from "react"

const customEvent = { 
  sideMenuLeftTransition: 'side-menu-container/left-transition-finished'
}
export const sideMenuContainerLeftTransition = (event: TransitionEvent<HTMLDivElement>) => {
  if(event && event.propertyName === 'margin-left') {
    const transitionFinished = new CustomEvent(customEvent.sideMenuLeftTransition, { detail: {}})
    window.dispatchEvent(transitionFinished)
  }
}

export const addEventSideMenuTransitionFinished = (callback) => window.addEventListener(customEvent.sideMenuLeftTransition, callback)
export const removeEventSideMenuTransitionFinished = (callback) => window.removeEventListener(customEvent.sideMenuLeftTransition, callback)
