import { LiveUpdateSubscriptionService, LiveUpdateSubscriptionServiceMultiServiceTransportClientImpl } from '@websites.common/live-update-subscription-service';

import { gameCardUpdater } from './live-updater'
import TransportImpl from './transportImpl'
import { liveUpdateSettings } from './config'
import { isLiveCasinoEnabled } from './config/envVars';



const onGameCardUpdateServiceStatusChanged = () => {
  return;
}

const onGameCardUpdates = (updates) => {
  Object.entries(updates).forEach(
    ([id, updateObject]) => {
      gameCardUpdater.updateWithObject(updateObject);
    });
}

const createLiveCasinoGameCardUpdateService = () => {
  if (isLiveCasinoEnabled() === false) {
    return;
  }
  const updaterImpl = new LiveUpdateSubscriptionServiceMultiServiceTransportClientImpl('liveUpdate', TransportImpl.getInstance());
  const updater = new LiveUpdateSubscriptionService(
    updaterImpl,
    {
      idPropertyName: liveUpdateSettings.gameCardUpdateIdPropertyName,
      minUpdatePeriodMillis: liveUpdateSettings.minGameCardUpdatePeriodMillis,
    },
    onGameCardUpdateServiceStatusChanged,
    onGameCardUpdates
  )

  gameCardUpdater.setSubscriptionCallback((subscription) => {
    // TODO: update LiveUpdateSubscriptionService API to make this more convenient?
    updater.setSubscriptionBegin();

    subscription.forEach((cardId) => {
      updater.subscribe(String(cardId));
    });
    updater.setSubscriptionEnd();
  });

  updater.connect();
  updater.enableUpdates(true);
  return updater;
}

const liveCasinoGameCardUpdateService = createLiveCasinoGameCardUpdateService()

export {
  liveCasinoGameCardUpdateService,
}
