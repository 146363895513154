import Env from '@env';
import { isMobile } from '../../libs/uaparser'

export const intercomShutdown = () => {
  if (typeof window.Intercom == "function") {
    window.Intercom('shutdown')
  }
}

export const initializeIntercom = (customerId: string, firstName: string, email: string, brand: string, vipTier: string) => {
  const INTERCOM_APP_ID = Env.get('VITE_INTERCOM_APP_ID')
  const INTERCOM_API_BASE = Env.get('VITE_INTERCOM_API_BASE')
  const userId = customerId?.toUpperCase()

  const intercomConfig: { [key: string]: string | boolean } = {
    app_id: INTERCOM_APP_ID,
    api_base: INTERCOM_API_BASE,
    external_id: userId,
    user_id: userId,
    name: firstName,
    email,
    brand,
    vipTier,
  }

  if (isMobile()) {
    intercomConfig.hide_default_launcher = true
  }

  window.Intercom("boot", intercomConfig)
}
