import Env from '@env';
import React from 'react'
import { useLayoutEffect } from 'react'

import NeedHelpPopUp from '../../components/NeedHelpPopUp'
import { buildOptions } from './options'
import { gsettingToBrand } from './utils'
import { useFooter } from '../../../asyncData'
import 'universal-footer'

const Footer = () => {
  const siteKey = Env.get('VITE_GSETTING')
  const { data } = useFooter(siteKey !== 'sbnasite', siteKey === 'bolnasite')
  const footerData = siteKey === 'bolnasite' ? data?.data?.attributes : data

  useLayoutEffect(() => {
    const uFooterEl = document.querySelector('universal-footer')
    uFooterEl.options = buildOptions(footerData, siteKey)
  }, [footerData, siteKey])

  return (
    <footer>
      <NeedHelpPopUp />
      <universal-footer brand={gsettingToBrand(siteKey)}></universal-footer>
    </footer>
  )
}

export default Footer
