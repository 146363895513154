const iconCache = new Map();

/**
 * Dynamically loads an icon SVG file and caches it.
 * @param {string} name - The name of the icon to load.
 * @returns {Promise<string|null>} - A promise that resolves to the cached SVG content or null if an error occurs.
 */
const loadIcon = async name => {
  if (iconCache.has(name)) {
    return iconCache.get(name); // Always return from cache if available
  }

  try {
    const module = await import(`../../../assets/icons/${name}.svg?raw`);
    iconCache.set(name, module.default); // Cache the module default
  } catch (error) {
    console.error(`Error loading icon ${name}:`, error);
    iconCache.set(name, null); // Cache null to prevent future attempts for the same icon
  }

  return iconCache.get(name); // Always return from cache, whether it's the newly loaded icon or null
};

/**
 * Retrieves a list of available icon names from the icons directory.
 * @returns {string[]} - An array of icon names (without file extensions).
 */
const getNames = () => {
  const icons = import.meta.glob('../../../assets/icons/*.svg');
  return Object.keys(icons).map(key =>
    key.replace('../../../assets/icons/', '').replace('.svg', ''),
  );
};

export default loadIcon;
export { getNames };
