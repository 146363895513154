import merge from 'deepmerge'
import baseTheme from '../baseTheme'
import brandPalette from './palette'
import UniversalFooter from '../../../ui/external-components/Footer'
import noResultsSvg from '../../../assets/icons/no-results.svg?url'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const gamingCityPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette();
  const palette = merge(brandPalette, cssVariablesPalette);

  return {
    palette,
    brand: {
      namespace: 'gcsite',
      name: 'Gaming City',
      website: 'GamingCity.com',
      email: 'help@gamingcitycs.com',
      help: 'https://help.gamingcity.com/',
      themeColor: palette.quaternary800,
      bonusInfoUrl: 'https://help.gamingcity.com/bonus-tutorial-and-faq/',
    },
    gradients: {
      tertiaryGradient: `linear-gradient(to bottom, ${palette.tertiary500}, ${palette.tertiary100})`,
    },
    sideMenu: {
      selectedImg: 'brightness(0) saturate(100%) invert(56%) sepia(89%) saturate(885%) hue-rotate(114deg) brightness(97%) contrast(100%)',
      activeImg: 'brightness(0) saturate(100%) invert(3%) sepia(15%) saturate(991%) hue-rotate(53deg) brightness(98%) contrast(90%)',
    },
    tags: {
      tagColorDark: baseTheme.palette.neutral2,
      tagBgNew: '#028247',
      tagBgExclusive: palette.tertiary800,
    },
    opacity: {
      opacity40: 0.4,
      opacity90: 0.9,
    },
    fontFamily: {
      primaryFont: 'Open Sans',
      secondaryFont: 'Lato',
    },
    buttons: {
      primaryFrontColor: baseTheme.palette.neutral1,
      primarySolidColor: baseTheme.palette.neutral1,
      primarySolidBg: palette.secondary800,
    secondarySolidColor: baseTheme.palette.neutral1,
      secondarySolidBg: palette.primary800,
      iconColorDisabled: baseTheme.palette.neutral1_38,
      ghostBg: 'transparent',
      ghostColor: baseTheme.palette.neutral1,
    ghostSolidBg: baseTheme.palette.neutral1_9,
    ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: 'transparent',
      hover: {
        ghostSolidBg: baseTheme.palette.neutral1_16,
        ghostSolidColor: baseTheme.palette.neutral1,
        ghostBg: baseTheme.palette.neutral1_16,
      ghostColor: baseTheme.palette.neutral1,
    },
      pill:{
      bgColor: palette.primary800,
      bgColorHover: palette.tertiary800,
      color: baseTheme.palette.neutral1,
    }
  },
    pageHeading: {
      bgColor: palette.quaternary700,
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: baseTheme.palette.neutral1_60,
      variant: 'filled',
    },
    bonusTable: {
      headerSize: null,
      headColor: baseTheme.palette.neutral1,
      headBgColor: null,
      tableVariant: 'dark',
    },
    selectInput: {
      border: `2px solid ${baseTheme.palette.neutral1_16}`,
      borderColorHover: baseTheme.palette.neutral1_60,
      iconColor: baseTheme.palette.neutral1,
      color: baseTheme.palette.neutral1_87,
    },
    appBar: {
      background: palette.quaternary800,
      logoHeight: '4rem',
      logoHeightMd: '3rem',
    },
    infoModal: {
      background: palette.quaternary800,
    },
    carouselsTitle: {
      iconColor: baseTheme.palette.neutral1,
      linkColor: palette.tertiary500,
    },
    footer: <UniversalFooter />,
    needHelp: {
      mainColor: palette.secondary900,
    },
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'internal',
      url: `/cashier`,
    },
    myAccount: {
      securityCentral: {
        active: true,
        style: {
          background: '#143635',
          backgroundActive: '#12302F',
          tabItemBackgroundColor: '#041613',
          tabItemColor: '#636F6D',
          tabItemActiveColor: '#FFFFFF',
          accordionSectionColor: '#E0E0E0',
          accordionSectionActiveColor: '#D3A562',
          changePasswordAvatarColor: '#D3A562',
          changePasswordButtonBackgroundColor: 'linear-gradient(180deg, #d3a562 0%, #c07531 100%)',
          changePasswordTextButtonColor: '#041613',
        },
      },
    },
    intercom: {
      brand: 'gamingcity'
    },
  input: {
    color: baseTheme.palette.neutral1,
    colorAlt: baseTheme.palette.neutral2,
    bgColor: 'transparent',
    bgAltColor: baseTheme.palette.lightGray_150,
    borderColor: baseTheme.palette.neutral1_16,
    borderColorAlt: 'transparent',
    borderColorHover: baseTheme.palette.neutral1_60,
    placeholder: baseTheme.palette.neutral1_38,
    placeholderAlt: baseTheme.palette.neutral2_38,
  },
  searchModal: {
    headerBg: '#123333',
    highlightText: palette.primary800,
    active: false
  },
  switch: {
    thumbColor: baseTheme.palette.neutral1,
    thumbColorChecked: palette.secondary700,
    trackColor: baseTheme.palette.neutral3_60,
    trackColorChecked: palette.secondary700,
  },
  checkbox: {
    color: palette.primary800,
    colorHover: 'rgba(192,117,49, .16)',
  }
  }
}


export default gamingCityPartialTheme
