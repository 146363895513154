import Env from '@env';
// Helper function to get CSS variable value
import  pickBy  from 'lodash/pickBy'

const getCSSVarValue = (variableName: string): string => getComputedStyle(document.documentElement).getPropertyValue(variableName) ?? undefined;

const filterNullishValues = (obj) => pickBy(obj, value => value)

const cssVarBolMapping = () => ({
  sideMenu: getCSSVarValue('--ads-text-primary'),
  green300: getCSSVarValue('--ads-secondary-300'),
  //green match with secondary-500
  green: getCSSVarValue('--ads-secondary-main'),
  green700: getCSSVarValue('--ads-secondary-700'),
  btnSecondary: getCSSVarValue('--tertiary-darkest-grey'),
  tagBgNew: getCSSVarValue('--ads-primary-main'),
  primaryBlack: getCSSVarValue('--ads-text-primary'),
  primary: getCSSVarValue('--ads-primary-main'),
  primary700: getCSSVarValue('--ads-primary-700'),
  hoverBtn: getCSSVarValue('--ads-text-primary'),
  disabledBtnText: getCSSVarValue('--ads-text-disabled'),
  backdrop: getCSSVarValue('--ads-background-transparent'),
  gray: getCSSVarValue('--ads-text-disabled'),
  gray800: getCSSVarValue('--tertiary-darkest-grey'),
  gray900: getCSSVarValue('--ads-background-brand'),
  white: getCSSVarValue('--ads-common-white-main'),
  secondary150: getCSSVarValue('--ads-background-brand'),
  placeholder: getCSSVarValue('--ads-text-primary'),
  
  //Values pending of mapping against the figma designs
  // cardSubtitle: getCSSVarValue('--ads-cardSubtitle'),
  // cardHover: getCSSVarValue('--ads-cardHover'),
  // inputBg:  getCSSVarValue('--ads-inputBg'),
  // tableBg: '#5F6D7D33',
  // gray100: '#f3f3f3',
  // gray700: '#333333',
  // primary800: '#FCC400',
  // primary50: '#e6f6ec',
  // secondary900: '#0087F6',
  // secondary700: '#0087F6',
  // secondary600: '#f88d20',
  // secondary500: '#003B4D', //BETNAVY
  // secondary800: '#0087F6',
  // secondary100: '#fee0b5',
  // secondary50: '#fff3e1',
  // tertiary800: '#FCC400',
  // tertiary700: '#4e9b33',
  // tertiary600: '#61af3d',
  // tertiary500: '#003b4d',
  // tertiary400: '#005974',
  // tertiary100: '#d4ecc7',
  // tertiary900: '#239cff',
  // quaternary800: '#EE3536',
  // quaternary700: '#E7E7E7',
  // quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  // quaternary700_0: 'rgba(18, 18, 18, 0)',
  // placeholder20: '#3d3d3d',
  // placeholder80: '#d6d6d6',
})

const cssVarSBMapping = () => ({
  sideMenu: getCSSVarValue('--ads-text-primary'),
  btnPrimary: getCSSVarValue('--ads-secondary-400'),
  btnPrimaryHover: getCSSVarValue('--ads-secondary-300'),
  btnSecondary: getCSSVarValue('--ads-primary-main'),
  primaryBlack: getCSSVarValue('--ads-grey-900'),
  primaryBlue: getCSSVarValue('--ads-terciary-500'),
  primary: getCSSVarValue('--ads-terciary-500'),
  hoverBtn: getCSSVarValue('--ads-terciary-300'),
  disabledBtnText: getCSSVarValue('--ads-text-disabled'),
  darkBlueCustom: getCSSVarValue('--ads-terciary-900'),
  backdrop: getCSSVarValue('--ads-background-transparent'),
  gray100: getCSSVarValue('--ads-grey-100'),
  gray900: getCSSVarValue('--ads-grey-900'),
  white: getCSSVarValue('--ads-common-white-main'),
  white_01: getCSSVarValue('--ads-common-white-hover'),
  tertiary900: getCSSVarValue('--ads-primary-main'),
  primary800: getCSSVarValue('--ads-secondary-400'),
  secondary900: getCSSVarValue('--ads-primary-main'),
  secondary700: getCSSVarValue('--ads-primary-main'),
  secondary500: getCSSVarValue('--ads-primary-main'),
  secondary800: getCSSVarValue('--ads-primary-main'),
  secondary150: getCSSVarValue('--ads-terciary-900'),
  tertiary800: getCSSVarValue('--ads-secondary-400'),
  tertiary500: getCSSVarValue('--ads-secondary-400'),
  quaternary800:  getCSSVarValue('--ads-terciary-500'),
  placeholder: getCSSVarValue('--ads-terciary-500'),
  breadCrumb: getCSSVarValue('--ads-terciary-500'),

  //Values pending of mapping against the figma designs
  // tagBgNew: '#EE3536',
  // cardSubtitle: getCSSVarValue('--ads-cardSubtitle'),
  // cardHover: getCSSVarValue('--ads-cardHover'),
  // inputBg:  getCSSVarValue('--ads-inputBg'),
  // tableBg: '#5F6D7D33',
  // gray700: '#ABB3BC',
  // sbDarkGraphite: '#16181A',
  // primary50: '#e6f6ec',
  // secondary600: '#f88d20',
  // secondary100: '#fee0b5',
  // secondary50: '#fff3e1',
  // tertiary700: '#4e9b33',
  // tertiary600: '#61af3d',
  // tertiary100: '#d4ecc7',
  // tertiary900: '#239cff',
  // quaternary700: '#E7E7E7',
  // quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  // quaternary700_0: 'rgba(18, 18, 18, 0)',
  // placeholder20: '#3d3d3d',
  // placeholder80: '#d6d6d6',
  // chevron: '#828282',
})

const cssVarGeneralMapping = () => ({
  primary800: getCSSVarValue('--ads-secondary-800'),
  primary50: getCSSVarValue('--ads-secondary-50'),
  secondary900: getCSSVarValue('--ads-primary-900'),
  secondary800: getCSSVarValue('--ads-primary-800'),
  secondary700: getCSSVarValue('--ads-primary-700'),
  secondary600: getCSSVarValue('--ads-primary-600'),
  secondary500: getCSSVarValue('--ads-primary-500'),
  secondary100: getCSSVarValue('--ads-primary-100'),
  secondary50: getCSSVarValue('--ads-primary-50'),
  tertiary800: getCSSVarValue('--ads-terciary-900'),
  tertiary750: getCSSVarValue('--ads-terciary-800'),
  tertiary700: getCSSVarValue('--ads-terciary-700'),
  tertiary600: getCSSVarValue('--ads-terciary-600'),
  tertiary500: getCSSVarValue('--ads-terciary-500'),
  tertiary100: getCSSVarValue('--ads-terciary-100'),
  quaternary800: getCSSVarValue('--ads-background-800'),
  quaternary700: getCSSVarValue('--ads-background-800'),
  links: getCSSVarValue('--ads-terciary-500'),
  white: getCSSVarValue('--ads-common-white-main'),
  breadCrumb: getCSSVarValue('--ads-common-white-main'),
  
  //Values pending of mapping against the figma designs
  // quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  // quaternary700_0: 'rgba(18, 18, 18, 0)',
  // placeholder: '#333333',
  // placeholder20: '#3d3d3d',
  // placeholder80: '#d6d6d6',
  // gray: '#363535',
  // grayLight: '#979797',
  // grayLightSecondary: '#90918D',
  // accent: '#FD9823',
  // chevron: '#828282',
})

const getCssVariablesPalette = () => {
  const brand = Env.get('VITE_GSETTING')
  let palette;

   switch (brand) {
    case 'bolnasite':
      palette = cssVarBolMapping()
      break;
    case 'sbnasite': 
      palette = cssVarSBMapping()
      break;
    case 'wcnasite': 
      palette = cssVarGeneralMapping()
      break;
    default:
      palette = cssVarGeneralMapping()
      break;
   }
    return { ...filterNullishValues(palette) };

}

export { getCssVariablesPalette }