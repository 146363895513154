import merge from 'deepmerge'
import brandPalette from './palette'
import baseTheme from '../baseTheme'
import noResultsSvg from '../../../assets/icons/no-results.svg?url'
import UniversalFooter from '../../../ui/external-components/Footer'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const wildCasinoPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette();
  const palette = merge(brandPalette, cssVariablesPalette);

  return {
    palette,
    brand: {
      namespace: 'wcnasite',
      name: 'Wild Casino',
      website: 'WildCasino.ag',
      email: 'help@wildcasinocs.ag',
      help: 'https://help.wildcasino.ag/',
      themeColor: palette.quaternary800,
      bonusInfoUrl: 'https://help.wildcasino.ag/bonus-tutorial-and-faq/',
    },
    sideMenu: {
      selectedImg: 'brightness(0) saturate(100%) invert(78%) sepia(24%) saturate(7072%) hue-rotate(343deg) brightness(103%) contrast(98%)',
      activeImg: 'brightness(0) saturate(100%) invert(3%) sepia(15%) saturate(991%) hue-rotate(53deg) brightness(98%) contrast(90%)',
    },
    opacity: {
      opacity40: 0.4,
      opacity90: 0.9,
    },
    fontFamily: {
      primaryFont: 'Open Sans',
      secondaryFont: 'Lato',
    },
    buttons: {
      primaryFrontColor: baseTheme.palette.neutral1,
      primarySolidColor: baseTheme.palette.neutral1,
      secondarySolidColor: baseTheme.palette.neutral1,
      secondarySolidBg: palette.primary800,
      iconColorDisabled: baseTheme.palette.neutral1_38,
      ghostSolidBg: 'transparent',
      ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: baseTheme.palette.neutral1,
      hover: {
        ghostSolidBg: baseTheme.palette.neutral1,
        ghostSolidColor: baseTheme.palette.neutral1,
      },
    },
    pageHeading: {
      bgColor: palette.quaternary700,
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: baseTheme.palette.neutral1_60,
      variant: 'filled',
    },
    bonusTable: {
      headerSize: null,
      headColor: baseTheme.palette.neutral1,
      headBgColor: null,
      tableVariant: 'dark',
    },
    selectInput: {
      border: `2px solid ${baseTheme.palette.neutral1_16}`,
      borderColorHover: baseTheme.palette.neutral1_60,
      iconColor: baseTheme.palette.neutral1,
      color: baseTheme.palette.neutral1_87,
    },
    appBar: {
      background: palette.quaternary800,
      logoHeight: '4rem',
      logoHeightMd: '3rem',
    },
    infoModal: {
      background: palette.quaternary800,
    },
    carouselsTitle: {
      iconColor: baseTheme.palette.neutral1,
      linkColor: palette.links,
    },
    needHelp: {
      mainColor: palette.tertiary750,
    },
    header: {
      logoHeight: '100%',
      logoMarginLeft: '-18px',
    },
    footer: <UniversalFooter />,
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'internal',
      url: `/cashier`,
    },
    intercom: {
      brand: 'wildcasino'
    },
    input: {
      color: baseTheme.palette.neutral1,
      colorAlt: baseTheme.palette.neutral2,
      bgColor: 'transparent',
      bgAltColor: baseTheme.palette.neutral1,
      borderColor: baseTheme.palette.neutral1_16,
      borderColorAlt: 'transparent',
      borderColorHover: baseTheme.palette.neutral1_60,
      placeholder: baseTheme.palette.neutral1_38,
      placeholderAlt: baseTheme.palette.neutral2_38,
    },
    searchModal: {
      headerBg: '#123333',
      active: false,
    },
    switch: {
      thumbColor: baseTheme.palette.neutral1,
      thumbColorChecked: palette.secondary700,
      trackColor: baseTheme.palette.neutral3_60,
      trackColorChecked: palette.secondary700,
    }
  }
}
export default wildCasinoPartialTheme
